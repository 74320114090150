import { ApiGatewayPathBuilder, Env, IPlatformAdminEnvironment } from 'shared-lib'

const env: Env = 'qa'
const pathBuilder = new ApiGatewayPathBuilder(env)

export const environment: IPlatformAdminEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBxgYkpbTWg3Tf0roc8Ec2bbquWN7lImPc',
    authDomain: 'cnect-qa-100.firebaseapp.com',
    projectId: 'cnect-qa-100',
    storageBucket: 'cnect-qa-100.appspot.com',
    messagingSenderId: '148414587373',
    appId: '1:148414587373:web:0427f301d61a24f38ec7e4',
    databaseURL: 'https://cnect-qa-100.firebaseio.com',
    measurementId: 'G-FCV271TYPQ',
  },

  services: {
    jobs: pathBuilder.build('jobs'),
    jobsAutomation: pathBuilder.build('jobsAutomation'),
    registration: pathBuilder.build('registration'),
    user: pathBuilder.build('user'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    customer: pathBuilder.build('customer'),
    notifications: pathBuilder.build('notifications'),
    product: pathBuilder.build('product'),
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false,
  themesEnabled: true,
  v2ReCaptcha: '6Lc2pOQcAAAAAI_oy89EVGVynt11riErd494oSOd',
  tenantId: 'PLATFORM-USER-tcg9v',
  algoliaAppId: 'XSN9B9X19Y',
  cdnCname: 'qa-content.cnected.com',
  version: 'ebb3816',
  basePlatformHost: 'qa-admin.cnect.jobs',
}
